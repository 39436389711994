import React from "react";
import conference from './../assets/image/conference.png'
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Button } from "@mui/material";

const Conference = ( ) => {
  return (
      <div className="m-4">
        <h1 className="text-light-orange uppercase text-xl md:text-3xl font-bold">Our Conference</h1>
          <div className="flex flex-col justify-between m-4 lg:basis-1/2 shrink">
            <div className="text-2xl font-semibold text-center">
            International Conference on Advanced Manufacturing and Materials for Sustainable Mining Equipment and Technologies 2025
              <div className="mt-8 text-red-600">                <span className="text-xl font-semibold"> <EventAvailableIcon sx={{width: "30px"}}/>29 Sept - 3 Oct, 2025</span>
              </div>
              <div className="mt-4">
                <span className="text-gray-700"><LocationOnIcon sx={{width: "30px"}}/>Wollongong, NSW, AUSTRALIA</span>
              </div>
              <div className="m-4">
                <a href='https://www.ammet2025.com.au' target="_blank" rel="noopener noreferrer">
                  <Button variant="outlined" color="secondary" sx={{bgcolor: "#940707", color: "#fff", "&:hover": {bgcolor: "#940707", opacity: 0.9}, width: "200px", height:"60px", borderRadius: 0, border: 0, fontWeight: 600}}>Watch this Space</Button>
                </a>
              </div>
            </div>
          </div>
          <div className="basis-full lg:basis-1/3 grow">
            <img src={conference} alt="conference-img" className="w-full" />
          </div>
      </div>
    )
}

export default Conference;